<template>
  <div ref="root" class="home-case">
    <div class="home-case__content">
      <div v-if="content.cases?.items?.length" class="case-outer">
        <div class="case">
          <button
            ref="shakerRef"
            class="shaker"
            type="button"
            :aria-label="t('case-random')"
            @click.prevent
          >
            <GCtaRounded name="hold" size="small" class="shaker__cta" />
          </button>
          <h2
            :key="currentCase?.slug"
            ref="titleRef"
            class="h1-case split-title case__title"
          >
            {{ cleanBackslash(currentCase?.title) }}
          </h2>

          <ul
            v-if="currentCase.services"
            ref="servicesRef"
            class="case__services"
          >
            <li
              v-for="service in currentCase.services"
              :key="service.value"
              class="case__services__item"
            >
              {{ service.label }}
            </li>
          </ul>

          <div ref="linkRef" class="case__link">
            <GBtn tag="GAction" size="small" :url="content.cases?.cta?.url">
              {{ content.cases?.cta?.title }}
            </GBtn>
          </div>
        </div>

        <div v-if="content.posts?.length" class="blog">
          <div class="blog__header">
            <span class="section-label">{{ content.postsTitle }}</span>
            <GBtn
              tag="GAction"
              :url="content.allPostsUrl"
              class="blog__header__cta"
            >
              {{ content.allPostsLabel }}
            </GBtn>
          </div>

          <div class="blog__links">
            <GAction
              v-for="post in content.posts"
              :key="post.url"
              :url="post.url"
              :title="post.title"
              @click.prevent="onCaseClick(post.url)"
              class="blog__links__item"
            >
              <div class="blog__links__item__content">
                <span class="blog__links__item__content__date section-label">
                  {{ post.date }}
                </span>

                <span class="blog__links__item__content__title p-medium">
                  {{ post.title }}
                </span>
              </div>

              <div class="blog__links__item__link">
                <SvgSprite symbol="icons-visibility" size="18" />

                <span>{{ content.postsLabel }}</span>
              </div>
            </GAction>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import {
  computed,
  nextTick,
  ref,
  onMounted,
  PropType,
  onBeforeUnmount,
} from 'vue'
import { useRouter } from 'vue-router'

import { createSplitTitle, SplitTitle } from '@/animations/splitTitle'
import { setColors } from '@/modules/colors'
import { Colors } from '@/types'
import { CaseHighlight } from '@/types/views/cases'
import { Content } from '@/types/views/home'
import { wait } from '@/utils/async'
import { cleanBackslash } from '@/utils/string'
import { useChromeStore } from '@/stores/chrome'
import ScrollTrigger from 'gsap/ScrollTrigger'

import { poster } from '@/components/global/Poster.vue'

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  content: {
    type: Object as PropType<Content>,
    required: true,
  },
})

/* eslint-disable func-call-spacing */
const emit = defineEmits<{
  (e: 'color-change', colors: Colors): void
}>()

const router = useRouter()

const cases: CaseHighlight[] = props.content.cases?.items || []

const caseIndex = ref(0)
const currentCase = computed(() => cases[caseIndex.value])

const shakerRef = ref<HTMLElement>()
const titleRef = ref<HTMLElement>()
const linkRef = ref<HTMLElement>()
const servicesRef = ref<HTMLElement>()

const { t } = useChromeStore()

let splitTitle: SplitTitle

emit('color-change', currentCase.value?.colors)

const createAppearTl = () => {
  const items = []

  items.push(shakerRef.value)

  // items.push(linkRef.value)

  return gsap
    .timeline({
      paused: true,
    })
    .set(titleRef.value || null, { opacity: 1 })
    .add('start')
    .fromTo(
      items,
      {
        y: '-20px',
        opacity: 0,
      },
      {
        duration: 0.35,
        ease: 'power3.out',
        y: '0px',
        opacity: 1,
        stagger: 0.1,
        clearProps: 'all',
      }
    )
    .call(
      () => {
        splitTitle.intro.play()
      },
      undefined,
      '-=0.2'
    )
    .fromTo(
      [servicesRef.value, linkRef.value],
      {
        x: '-20px',
        opacity: 0,
      },
      {
        duration: 0.35,
        ease: 'power3.out',
        x: '0px',
        opacity: 1,
        clearProps: 'all',
        stagger: 0.1,
      },
      '+=0.25'
    )
}

const createLeaveTl = () =>
  gsap
    .timeline({
      paused: true,
    })
    .add('start')
    .fromTo(
      [servicesRef.value, linkRef.value],
      {
        x: '0px',
        opacity: 1,
      },
      {
        duration: 0.35,
        ease: 'power3.out',
        x: '20px',
        opacity: 0,
        stagger: 0.1,
      }
    )
    .call(
      () => {
        splitTitle.outro.play()
      },
      undefined,
      'start'
    )
    .fromTo(
      // [headlineRef.value, linkRef.value],
      // ? remove if transition
      [shakerRef.value],
      {
        y: '0px',
        opacity: 1,
      },
      {
        duration: 0.35,
        ease: 'power3.out',
        y: '-20px',
        opacity: 0,
        stagger: 0.1,
      },
      'start+=0.25'
    )
    .set(titleRef.value || null, { opacity: 0 })
    .call(() => {
      splitTitle.clear()
    })

const onCaseClick = async (url?: string) => {
  clearTimeout(interval)

  if (window.scrollY > 0) {
    await gsap
      .to(window, {
        scrollTo: { x: 0, y: 0 },
        duration: 0.5,
        ease: 'power2.in.out',
      })
      .then()
  }
  await createLeaveTl().play().then()
  router.push(url || currentCase.value.url)
}

const onShake = async () => {
  let nextIndex = caseIndex.value + 1

  if (nextIndex >= cases.length) {
    nextIndex = 0
  }

  if (nextIndex === caseIndex.value) {
    onShake()
  } else {
    // TODO: move color change to Poster and sync with animation.
    // Or pass durations from here to children (plus facile, moins clean)…
    // aka outDuration (ou similaire) === bon compromis ?

    // Make it leave
    createLeaveTl().play().then()

    // Update index and trigger poster change
    await wait(0.35)
    caseIndex.value = nextIndex
    poster.change?.(cases[caseIndex.value])

    // Wait for template titleRef update.
    // Then, hide it befaore animation
    nextTick(async () => {
      gsap.set(titleRef.value || null, { opacity: 0 })

      // Trigger colors transition
      setColors(currentCase.value?.colors, 1.2)
      await wait(1.6)
      // Dispatch color change event
      emit('color-change', currentCase.value?.colors)

      // Update SplitTitle with new content
      if (titleRef.value) {
        splitTitle = createSplitTitle(titleRef.value)
      }

      // Make it appear
      await createAppearTl().play().then()
    })
  }
}

let interval: ReturnType<typeof setTimeout>
const repeat = (timeout = 8000) => {
  interval = setTimeout(() => {
    clearTimeout(interval)
    onShake().then(() => {
      repeat()
    })
  }, timeout)
}

const root = ref<HTMLElement>()
const scrollTrigger = () => {
  ScrollTrigger.create({
    start: 'top bottom',
    end: 'bottom top',
    onEnter: () => {
      repeat()
    },
    onEnterBack: () => {
      clearInterval(interval)
      repeat()
    },
    onLeave: () => {
      clearInterval(interval)
    },
    onLeaveBack: () => {
      clearInterval(interval)
    },
    trigger: root.value,
  })
}

onMounted(async () => {
  if (!shakerRef.value || !titleRef.value || !linkRef.value) {
    return
  }

  splitTitle = createSplitTitle(titleRef.value)

  const appearTl = createAppearTl()

  poster.move?.({ template: 'home' }, false)
  setColors(currentCase.value?.colors)

  await poster.init?.(currentCase.value)
  await appearTl.play().then(scrollTrigger)
})

onBeforeUnmount(() => {
  clearTimeout(interval)
})
</script>

<style lang="scss" scoped>
$bp: l;

.home-case,
[class*='home-case--'] {
  position: relative;
  height: 100%;
  min-height: calc(100 * var(--vh));
}

.home-case__content {
  position: absolute;
  // right: $home-header-posts-width + 4rem;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: $home-header-bottom;
}

.shaker {
  margin-left: -1.2rem;
  background: transparent;
  border: 0;
  pointer-events: none;
  cursor: none;
}

.shaker__cta {
  pointer-events: none;
}

.case-outer {
  // prettier-ignore
  @include fluid(gap,(xxs: 50px, xxl: 20px));

  display: flex;
  flex-direction: column;

  @include mq($bp) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.case {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.case__headline {
  text-transform: uppercase;
}

.case__title {
  // prettier-ignore
  @include fluid(font-size,(xxs: 35px, xxl: 80px));
}

.case__services {
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.case__services__item {
  color: var(--c-foreground);
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
  list-style-type: none;
}

.case__link {
  display: inline-block;
  cursor: pointer;
  fill: var(--c-foreground);
}

.blog {
  flex-shrink: 0;

  @include mq($bp) {
    width: 30%;
  }
}

.blog__header {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.blog__header__cta {
  padding: 0;
  border: 0;

  :deep(.btn__label) {
    font-size: 0.9rem;
  }

  :deep(.btn__icon) {
    width: 1.6rem;
  }

  :deep(svg) {
    transition: transform 0.15s;
  }

  &:hover:not(:disabled) {
    color: var(--c-foreground);
    background-color: transparent;

    :deep(svg) {
      fill: var(--c-foreground);
      /* stylelint-disable-next-line declaration-no-important */
      transform: translateX(0.5rem) !important;
    }
  }
}

.blog__links {
  margin: 1rem 0 0;
  padding: 0;
}

.blog__links__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  color: var(--c-foreground);
  border-top: 1px solid var(--c-foreground);
  list-style-type: none;

  &:hover {
    filter: brightness(1.2);
  }
}

.blog__links__item__content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0;
}

.blog__links__item__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
  color: var(--c-foreground);
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  :deep(svg) {
    fill: var(--c-foreground);
  }
}
</style>
